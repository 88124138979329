import { isBackendDateValid, isTimeValid, isEndtimeBeforeStarttime, isMeetingTimeLessThan2Hours } from './timeService'
import { MEETINGTYPE_MEETING, MEETINGTYPE_PARTY, MEETINGTYPE_SAUNA, TABLECONFIGURATIONTYPE_ANYTABLEMODEL } from '../state/reduxApp'

/*
  Unit test can be run using npm run test -command
  searchService.test.js
*/

/* #region usecases */
const AllFieldsAreEmpty0A = reduxSearch => {
  /*  Use case 0A: All fields are empty
      Show results filtered by:
        none

      Layout specs
        Disable tableform input
        Disable date input
        Disable start time input
        Disable end time input
        Disable additional selections
 */

  if (isPersonCountValueInteger(reduxSearch)) return false
  if (hasSearchBoxText(reduxSearch)) return false
  if (dateTimesOrTableFormHaveValue(reduxSearch)) return false
  return true
}
const onlyPlaceHasValue0B = reduxSearch => {
  /*  Use case 0B: Only place has value
        Show results filtered by:
          location

        Layout specs
          Disable start time input
          Disable end time input
   */
  if (isPersonCountValueInteger(reduxSearch)) return false
  if (dateTimesOrTableFormHaveValue(reduxSearch)) return false
  if (hasSearchBoxText(reduxSearch)) return true
  return false
}
const OnlyPersonCountHasValue0C = reduxSearch => {
  /*  Use case 0C: Person count has value
        Show results filtered by:
          personCount

        Layout specs
          Disable start time input
          Disable end time input
  */
  if (dateTimesOrTableFormHaveValue(reduxSearch)) return false
  if (hasSearchBoxText(reduxSearch)) return false
  if (isPersonCountValueInteger(reduxSearch)) return true
  return false
}
const onlyPlaceAndPersonCountHaveValue0D = reduxSearch => {
  /*  Show results filtered by:
        location
        person
  */
  if (dateTimesOrTableFormHaveValue(reduxSearch)) return false
  if (hasSearchBoxText(reduxSearch) && isPersonCountValueInteger(reduxSearch)) return true
  return false
}
const onlyPlacePersonCountAndTableformHaveValue0E3D = reduxSearch => {
  /*  Use case 0E: Place, person count and tableform have value
        Show results filtered by:
          location
          person
          tableform

        Layout specs
          Disable start time input
          Disable end time input
   */

  if (hasDateText(reduxSearch) || hasStartTimeText(reduxSearch) || hasEndTimeText(reduxSearch)) return false
  if (hasSearchBoxText(reduxSearch) && isPersonCountValueInteger(reduxSearch) && hasTableFormBeenSelected(reduxSearch)) return true
  return false
}
const onlyPlacePersonCountTableformAndDateHaveValue0F2C = reduxSearch => {
  /*  Use case 0F: Place, person count,
        tableform and date have value

        Show results filtered by:
          location
          person
          tableform
          date

        Layout specs
          Disable end time input
   */

  if (hasStartTimeText(reduxSearch) || hasEndTimeText(reduxSearch)) return false
  if (hasSearchBoxText(reduxSearch) && isPersonCountValueInteger(reduxSearch) && hasTableFormBeenSelected(reduxSearch) && hasDateText(reduxSearch)) return true
  return false
}
const endtimeDeletedOtherRequiredFieldsHaveValueAndStarttimeHasValue0G2A = reduxSearch => {
  /*  Use case 0G/2A: Place, person count, tableform, date and start time have value
        Show results filtered by:
          location
          person
          tableform
          date

        Layout specs
          Disable end time input
   */

  if (hasEndTimeText(reduxSearch)) return false
  if ((hasSearchBoxText(reduxSearch) || isPersonCountValueInteger(reduxSearch)) && hasDateText(reduxSearch) && hasStartTimeText(reduxSearch)) return true
  return false
}
// const allFieldsHaveValue1A = () => {
//   /*  1: All fields have a value
//         Show results filtered by:
//         location (if given)
//         person count (if given)
//         table configuration
//         date (if given)
//         time (if given)   */
//   throw new Error('Do not use this')
// }
// export const endtimeDeleted0G2A = reduxSearch => {
//   return endtimeDeletedOtherRequiredFieldsHaveValueAndStarttimeHasValue0G2A(reduxSearch)
// }
const starttimeDeletedOtherRequiredFieldsHaveValue2B = reduxSearch => {
  /*  2B: start time is deleted
        Show results filtered by:
        location
        person count
        table configuration
        date
        time (start / end)

        Layout specs
          Highlight start time input with error red.
          Disable end time input (preserve value). */

  if (
    // !hasStartTimeText(reduxSearch) &&
    // hasSearchBoxText(reduxSearch) &&
    // isPersonCountValueInteger(reduxSearch) &&
    // hasTableFormBeenSelected(reduxSearch) &&
    // hasDateText(reduxSearch) &&
    // hasEndTimeText(reduxSearch)
    !hasStartTimeText(reduxSearch) &&
    (hasSearchBoxText(reduxSearch) || isPersonCountValueInteger(reduxSearch)) &&
    hasDateText(reduxSearch) &&
    hasEndTimeText(reduxSearch)
  )
    return true
  return false
}
// export const starttimeAndEndtimeDeleted0F2C = reduxSearch => {
//   /*  2C: start & end time are deleted
//         Show results filtered by:
//         location
//         person count
//         table configuration
//         date
//         time (start / end)  */

//   if (onlyPlacePersonCountTableformAndDateHaveValue0F2C(reduxSearch)) return true
//   return false
// }
const dateDeletedOtherFieldsHaveValue3A = reduxSearch => {
  /*  3A: date is deleted, while start and / or end time values are still in place
        Show results filtered by:
        location
        person count
        table configuration

        Layout specs
          Highlight date input with error red.
          Disable start time input (preserve value).
          Disable end time input (preserve value). */

  if (!reduxSearch.reservationDate) return true
  if (reduxSearch.reservationDate.length < 10) return true
}

const dateDeletedStartTimeFieldHasValue3B = reduxSearch => {
  /*  Use case 3B: date and end time are deleted, start time value is still in place
        Show results filtered by:
        location
        person count
        table configuration

        Layout specs
          Highlight date input with error red.
          Disable start time input (preserve value).
          Disable end time input.
  */

  if (!hasStartTimeText(reduxSearch)) return false
  if (!reduxSearch.reservationDate) return true
  if (reduxSearch.reservationDate.length < 10) return true
}

const dateDeletedEndTimeFieldHasValue3C = reduxSearch => {
  /* Use case 3C: date and start time are deleted, end time value is still in place
        Show results filtered by:
        location
        person count
        table configuration

        Layout specs
          Highlight date input with error red.
          Disable start time input.
          Disable end time input (preserve value).
      */
  if (!hasEndTimeText(reduxSearch)) return false
  if (!reduxSearch.reservationDate) return true
  if (reduxSearch.reservationDate.length < 10) return true
}

// export const dateStarttimeAndEndtimeDeleted3D0E = reduxSearch => {
//   if (onlyPlacePersonCountAndTableformHaveValue0E3D(reduxSearch)) return true
//   return false
// }
const tableConfigurationDeletedOtherFieldsHaveValue4 = reduxSearch => {
  /*  Use case 4: Table configuration is deleted (set to ”any tablemodel”)
        Show results filtered by:
        location (if given)
        person count (if given)
        table configuration
        date (if given)
        time (if given)  */
  return !hasTableFormBeenSelected(reduxSearch)
}
// const personCountDeletedOtherFieldsHaveValue5A = () => {
//   /*  Use case 5A: Person count is deleted, location still has value
//         Show results filtered by:
//         location
//         person count
//         table configuration (if given)
//         date (if given)
//         time (if given)  */
//   throw new Error('Do not use this')
// }
// const locationDeletedOtherFieldsHaveValue5B = () => {
//   /*  Use case 5B: Location is deleted, person count still has value
//         Show results filtered by:
//         location
//         person count
//         table configuration (if given)
//         date (if given)
//         time (if given)  */
//   throw new Error('Do not use this')
// }
const placeAndPersonCountDeletedButOtherFieldHasValue5C = reduxSearch => {
  /*  Use case 5C: Place and person count are deleted, but at least one of other fields has a value
        Show results filtered by:
        location
        person count
        table configuration (if given)
        date (if given)
        time (if given)

        Layout specs
          Highlight location input with error red.
          Highlight person count input with error red.
          Disable table configuration input (preserve value).
          Disable datepicker (preserve value).
          Disable start time input (preserve value).
          Disable end time input (preserve value).
   */
  if (isPersonCountValueInteger(reduxSearch)) return false
  if (hasSearchBoxText(reduxSearch)) return false
  if (dateTimesOrTableFormHaveValue(reduxSearch)) return true
  return false
}
const additionalSelectionsSectionIsOpenedBeforeAnyFilteringIsDone6A = reduxSearch => {
  /*  Use case 6A: additional selections section is opened before any filtering is done
        Show results filtered by:
          none

        Layout specs
          Disable additional selections
   */
  if (isPersonCountValueInteger(reduxSearch)) return false
  if (hasSearchBoxText(reduxSearch)) return false
  return true
}

const endtimeBeforeStarttime7A = reduxSearch => {
  return isEndtimeBeforeStarttime(reduxSearch)
}

const meetingTimeLessThan2hours7F = reduxSearch => {
  return isMeetingTimeLessThan2Hours(reduxSearch)
}
/*
  Use case 6B: place is filled, disabled fields become available.

  // TODO: Implement unit test when additionals filtering is done
  Use case 6C: place is filled and additional selections are made.
  Show results filtered by:
        location
        Additionals

  Use case 6D: place is removed while some additional selections are still present
*/

const offlineVenuesOnly = reduxSearch => {
  /* Pietari hotels don't have availabilty because they are not in Hessu */
  if (reduxSearch.offlineEstablishmentsOnly) return true
  return false
}
const meetingSelected = reduxSearch => {
  return reduxSearch.meetingType === MEETINGTYPE_MEETING ? true : false
}
const saunaSelected = reduxSearch => {
  return reduxSearch.meetingType === MEETINGTYPE_SAUNA ? true : false
}
const partySelected = reduxSearch => {
  return reduxSearch.meetingType === MEETINGTYPE_PARTY ? true : false
}
/* #endregion */

/* #region local helpers */
const validateReduxSearch = reduxSearch => {
  if (!reduxSearch) throw new Error('reduxSearch missing')
  if (typeof reduxSearch !== 'object') throw new Error('reduxSearch object missing')
}
const isPersonCountValueInteger = reduxSearch => {
  if (reduxSearch.personCount && Number.isInteger(reduxSearch.personCount)) return true
  return false
}
const hasSearchBoxText = reduxSearch => {
  if (reduxSearch.boxText && reduxSearch.boxText.length > 0) return true
  return false
}
const hasTableFormBeenSelected = reduxSearch => {
  if (!reduxSearch) return false
  if (reduxSearch.tableConfigurationType && reduxSearch.tableConfigurationType !== TABLECONFIGURATIONTYPE_ANYTABLEMODEL) return true
  return false
}
const hasDateText = reduxSearch => {
  if (!reduxSearch) return false
  if (reduxSearch.reservationDate && reduxSearch.reservationDate.length > 0) return true
  return false
}
const hasStartTimeText = reduxSearch => {
  if (!reduxSearch) return false
  if (reduxSearch.startTime && reduxSearch.startTime.length > 0) return true
  return false
}
const hasEndTimeText = reduxSearch => {
  if (!reduxSearch) return false
  if (reduxSearch.endTime && reduxSearch.endTime.length > 0) return true
  return false
}

const dateTimesOrTableFormHaveValue = reduxSearch => {
  if (hasDateText(reduxSearch) || hasStartTimeText(reduxSearch) || hasEndTimeText(reduxSearch) || hasTableFormBeenSelected(reduxSearch)) return true
  return false
}

/* #endregion */

/* #region boxText */
export const boxTextValue = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (boxTextDisabled(reduxSearch)) return null
  return reduxSearch.boxText
}

export const boxTextDisabled = reduxSearch => {
  validateReduxSearch(reduxSearch)
  return false
}

export const boxTextHighlighted = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (placeAndPersonCountDeletedButOtherFieldHasValue5C(reduxSearch)) return true
  return false
}
/* #endregion */

/* #region personCount */
export const personCountValue = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (personCountDisabled(reduxSearch)) return null
  if (!reduxSearch.personCount) return null
  if (!Number(reduxSearch.personCount)) return null
  if (reduxSearch.personCount < 1) return null
  return reduxSearch.personCount
}

export const personCountDisabled = reduxSearch => {
  validateReduxSearch(reduxSearch)
  return false
}

export const personCountHighlighted = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (placeAndPersonCountDeletedButOtherFieldHasValue5C(reduxSearch)) return true
  return false
}
/* #endregion */

/* #region date */
export const dateValue = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (dateDisabled(reduxSearch)) return null
  if (!isBackendDateValid(reduxSearch.reservationDate)) return null
  return reduxSearch.reservationDate
}

export const dateDisabled = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (offlineVenuesOnly(reduxSearch)) return true
  if (AllFieldsAreEmpty0A(reduxSearch)) return true
  if (placeAndPersonCountDeletedButOtherFieldHasValue5C(reduxSearch)) return true
  return false
}

export const dateHighlighted = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (AllFieldsAreEmpty0A(reduxSearch)) return false
  if (onlyPlaceHasValue0B(reduxSearch)) return false
  if (OnlyPersonCountHasValue0C(reduxSearch)) return false
  if (onlyPlaceAndPersonCountHaveValue0D(reduxSearch)) return false
  if (onlyPlacePersonCountAndTableformHaveValue0E3D(reduxSearch)) return false
  if (dateDeletedStartTimeFieldHasValue3B(reduxSearch)) return true
  if (dateDeletedEndTimeFieldHasValue3C(reduxSearch)) return true
  return false
}
/* #endregion */

/* #region starttime */
export const starttimeValue = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (starttimeDisabled(reduxSearch)) return null
  if (endtimeBeforeStarttime7A(reduxSearch)) return null
  if (meetingTimeLessThan2hours7F(reduxSearch)) return null
  if (!isTimeValid(reduxSearch.startTime)) return null
  return reduxSearch.startTime
}
export const starttimeDisabled = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (AllFieldsAreEmpty0A(reduxSearch)) return true
  if (onlyPlaceHasValue0B(reduxSearch)) return true
  if (OnlyPersonCountHasValue0C(reduxSearch)) return true
  if (onlyPlaceAndPersonCountHaveValue0D(reduxSearch)) return true
  if (onlyPlacePersonCountAndTableformHaveValue0E3D(reduxSearch)) return true
  if (endtimeDeletedOtherRequiredFieldsHaveValueAndStarttimeHasValue0G2A(reduxSearch)) return true
  if (placeAndPersonCountDeletedButOtherFieldHasValue5C(reduxSearch)) return true
  if (offlineVenuesOnly(reduxSearch)) return true
  if (onlyPlacePersonCountAndTableformHaveValue0E3D(reduxSearch)) return false
  if (dateDeletedOtherFieldsHaveValue3A(reduxSearch)) return true
  return false
}
export const starttimeHighlighted = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (onlyPlacePersonCountAndTableformHaveValue0E3D(reduxSearch)) return false
  if (dateDeletedOtherFieldsHaveValue3A(reduxSearch)) return false
  if (starttimeDeletedOtherRequiredFieldsHaveValue2B(reduxSearch)) return true
  return false
}
/* #endregion */

/* #region endtime */
export const endtimeValue = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (endtimeBeforeStarttime7A(reduxSearch)) return null
  if (meetingTimeLessThan2hours7F(reduxSearch)) return null
  if (endtimeDisabled(reduxSearch)) return null
  if (!isTimeValid(reduxSearch.endTime)) return null
  return reduxSearch.endTime
}
export const endtimeDisabled = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (AllFieldsAreEmpty0A(reduxSearch)) return true
  if (onlyPlaceHasValue0B(reduxSearch)) return true
  if (OnlyPersonCountHasValue0C(reduxSearch)) return true
  if (onlyPlaceAndPersonCountHaveValue0D(reduxSearch)) return true
  if (onlyPlacePersonCountAndTableformHaveValue0E3D(reduxSearch)) return true
  if (onlyPlacePersonCountTableformAndDateHaveValue0F2C(reduxSearch)) return true
  if (placeAndPersonCountDeletedButOtherFieldHasValue5C(reduxSearch)) return true
  if (offlineVenuesOnly(reduxSearch)) return true
  if (onlyPlacePersonCountAndTableformHaveValue0E3D(reduxSearch)) return false
  if (dateDeletedOtherFieldsHaveValue3A(reduxSearch)) return true
  if (starttimeDeletedOtherRequiredFieldsHaveValue2B(reduxSearch)) return true
  return false
}
export const endtimeHighlighted = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (endtimeBeforeStarttime7A(reduxSearch)) return true
  if (meetingTimeLessThan2hours7F(reduxSearch)) return true
  if (onlyPlacePersonCountAndTableformHaveValue0E3D(reduxSearch)) return false
  if (endtimeDeletedOtherRequiredFieldsHaveValueAndStarttimeHasValue0G2A(reduxSearch)) return true
  if (dateDeletedOtherFieldsHaveValue3A(reduxSearch)) return false
  return false
}
/* #endregion */

/* #region tableConfiguration */
export const tableConfigurationValue = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (tableConfigurationDeletedOtherFieldsHaveValue4(reduxSearch)) return null
  if (tableConfigurationDisabled(reduxSearch)) return null
  return reduxSearch.tableConfigurationType
}
export const tableConfigurationDisabled = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (AllFieldsAreEmpty0A(reduxSearch)) return true
  if (placeAndPersonCountDeletedButOtherFieldHasValue5C(reduxSearch)) return true
  if (meetingSelected(reduxSearch)) return false
  if (saunaSelected(reduxSearch)) return true
  if (partySelected(reduxSearch)) return false
  return false
}
export const tableConfigurationHighlighted = reduxSearch => {
  validateReduxSearch(reduxSearch)
  return false
}
/* #endregion */

/* #region additional selections */
export const naturallightValue = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (additionalsDisabled(reduxSearch)) return null
  return reduxSearch.naturallight
}

export const additionalsDisabled = reduxSearch => {
  validateReduxSearch(reduxSearch)
  if (additionalSelectionsSectionIsOpenedBeforeAnyFilteringIsDone6A(reduxSearch)) return true
  return false
}
/* #endregion */
