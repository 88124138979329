import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { changeEdges, changeSearchBoxText, fetchVenuesPerPage, addPaginatedEdges, changeHotelName, searchResultsHasNextPage, searchResultsHasNotNextPage, changeEndCursor } from '../../state/reduxApp'
import { localizeObject } from '../../services/localizationService'
import { personCountValue, dateValue, starttimeValue, endtimeValue } from '../../services/searchService'
import LoadingAnimation from '../../../static/svgs/loading_animation.svg'
import roomStyles from '../room/rooms.module.css'
import { injectIntl } from '../../../plugins/gatsby-plugin-intl-custom'

class MeetingRoomsQuery extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  concatFetchedVenues(fetchedVenues) {
    if (!this.props.after) {
      this.props.dispatchEdges(fetchedVenues)
    } else {
      this.props.dispatchPaginatedEdges(fetchedVenues)
    }
  }

  handlePageInfo(pageInfo) {
    // If not pageInfo then dispatch not next
    if (!pageInfo || !pageInfo.hasNextPage) {
      this.props.dispatchSearchResultsHasNotNextPage()
      this.props.dispatchEndCursor(undefined)
      return
    }

    if (pageInfo.hasNextPage) {
      this.props.dispatchSearchResultsHasNextPage()
      this.props.dispatchEndCursor(pageInfo.endCursor)
    }
  }

  render() {
    let searchTerm = this.props.searchTerm !== '' ? this.props.searchTerm : null
    const establishmentID = this.props.establishmentID !== '' ? this.props.establishmentID : null
    let personCount = personCountValue(this.props.reduxSearch)
    const first = fetchVenuesPerPage
    let after = !this.props.after ? null : this.props.after.toString()

    // TODO: Add to service
    let locale
    switch (this.props.intl.locale) {
      case 'sv':
        locale = 'sv_FI'
        break
      case 'en':
        locale = 'en_GB'
        break
      case 'et':
        locale = 'et_EE'
        break
      default:
        locale = 'fi_FI'
        break
    }

    // TODO: Add to service
    let venueType = 'Meeting'
    if (this.props.meetingType === 'MEETINGTYPE_SAUNA') venueType = 'Sauna'
    if (this.props.meetingType === 'MEETINGTYPE_PARTY') venueType = 'Celebration'

    let date = dateValue(this.props.reduxSearch)
    let startTime = starttimeValue(this.props.reduxSearch)
    let endTime = endtimeValue(this.props.reduxSearch)
    let timeInput =
      startTime && endTime
        ? {
            start: startTime,
            end: endTime,
          }
        : null

    if (!searchTerm && !personCount && !establishmentID) {
      this.props.dispatchEdges(undefined)
      this.handlePageInfo(undefined)
      return null
    }

    if (!establishmentID) {
      return (
        <Query query={ROOMS_QUERY_STRING} variables={{ venueType, searchTerm, personCount, locale, first, after, date, timeInput }} fetchPolicy="cache-and-network" errorPolicy="all">
          {({ data, loading, error }) => {
            if (loading)
              return (
                <div className={roomStyles.loadingAnimationWrapper}>
                  <LoadingAnimation className={roomStyles.loadingAnimation} />
                </div>
              )
            if (error || !data || !data.venues || !data.venues.edges) {
              this.props.dispatchEdges(undefined)
              this.handlePageInfo(undefined)
              return null
            }

            this.concatFetchedVenues(data.venues.edges)
            this.handlePageInfo(data.venues.pageInfo)
            return null
          }}
        </Query>
      )
    }

    // This query is used when searching specific hotel with establishmentID
    if (establishmentID) {
      return (
        <Query query={ROOMS_QUERY_ESTABLISHMENTID} variables={{ venueType, establishmentID, personCount, locale, first, after, date, timeInput }} fetchPolicy="cache-and-network" errorPolicy="all">
          {({ data, loading, error }) => {
            if (loading)
              return (
                <div className={roomStyles.loadingAnimationWrapper}>
                  <LoadingAnimation className={roomStyles.loadingAnimation} />
                </div>
              )
            if (error || !data || !data.venues || !data.venues.edges) {
              this.props.dispatchEdges(undefined)
              this.handlePageInfo(undefined)
              return null
            }

            this.concatFetchedVenues(data.venues.edges)
            this.handlePageInfo(data.venues.pageInfo)

            // Redirection from sh.fi might contain hotel laariId e.g. http://localhost:8000/?establishmentId=533704490
            // If so then fill searchbox with hotelname
            if (window.location.href.includes('establishmentId')) {
              if (data.venues.edges.length < 1) return null
              const edge = data.venues.edges[0]
              if (!edge.establishment && !edge.establishment.name) return null
              const hotelName = localizeObject(edge.establishment.name, this.props.intl.locale)
              this.props.dispatchHotelName(hotelName)
              return null
            }

            this.props.dispatchHotelName('')
            return null
          }}
        </Query>
      )
    }

    this.props.dispatchEdges(undefined)
    this.props.dispatchSearchResultsHasNotNextPage()
    this.props.dispatchEndCursor(undefined)

    return null
  }
}

const mapStateToProps = state => {
  return {
    meetingType: state.search.meetingType,
    searchTerm: state.search.term,
    boxText: state.search.boxText,
    reduxSearch: state.search,
    establishmentID: state.search.venueId,
    after: state.searchResults.after,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchEdges: callbackLink => dispatch(changeEdges(callbackLink)),
  dispatchPaginatedEdges: callbackLink => dispatch(addPaginatedEdges(callbackLink)),
  dispatchSearchBoxText: callbackLink => dispatch(changeSearchBoxText(callbackLink)),
  dispatchHotelName: callbackLink => dispatch(changeHotelName(callbackLink)),
  dispatchSearchResultsHasNextPage: callbackLink => dispatch(searchResultsHasNextPage(callbackLink)),
  dispatchSearchResultsHasNotNextPage: callbackLink => dispatch(searchResultsHasNotNextPage(callbackLink)),
  dispatchEndCursor: callbackLink => dispatch(changeEndCursor(callbackLink)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MeetingRoomsQuery))

export const localizedStringFragments = {}
localizedStringFragments.localizedString = gql`
  fragment localizedString on LocalizedString {
    fi_FI
    en_GB
    sv_FI
    et_EE
  }
`

let tableConfigurationsFragments = {}
tableConfigurationsFragments.tableConfigurations = gql`
  fragment tableConfigurations on TableConfiguration {
    tableConfigurationName
    valueRange {
      max
    }
  }
`

const ROOMS_QUERY_STRING = gql`
  query MeetingRooms($venueType: VenueType!, $searchTerm: String, $personCount: Int, $locale: Locale, $first: Int, $after: String, $date: String, $timeInput: TimeInput) {
    venues(filter: { venueType: $venueType, establishmentType: [], searchTerm: $searchTerm, personCount: $personCount, locale: $locale, date: $date, time: $timeInput }, first: $first, after: $after) {
      edges {
        id
        name {
          ...localizedString
        }
        normalPriceCheapestPackage {
          type
          name {
            ...localizedString
          }
          price
          isMinimalPricePackage
        }
        images {
          alternativeText
          url
          description
        }
        establishment {
          id
          name {
            ...localizedString
          }
        }
        address {
          municipality {
            ...localizedString
          }
        }
        size
        maxCapacity
        attributes
        onlineReservable
        tableConfigurations {
          ...tableConfigurations
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${localizedStringFragments.localizedString}
  ${tableConfigurationsFragments.tableConfigurations}
`
const ROOMS_QUERY_ESTABLISHMENTID = gql`
  query Rooms($venueType: VenueType!, $establishmentID: ID!, $personCount: Int, $locale: Locale, $first: Int, $after: String, $date: String, $timeInput: TimeInput) {
    venues(
      filter: { venueType: $venueType, establishmentType: [], establishmentID: $establishmentID, personCount: $personCount, locale: $locale, date: $date, time: $timeInput }
      first: $first
      after: $after
    ) {
      edges {
        id
        name {
          ...localizedString
        }
        normalPriceCheapestPackage {
          type
          name {
            ...localizedString
          }
          price
          isMinimalPricePackage
        }
        images {
          alternativeText
          url
          description
        }
        establishment {
          id
          name {
            ...localizedString
          }
        }
        address {
          municipality {
            ...localizedString
          }
        }
        size
        maxCapacity
        attributes
        onlineReservable
        tableConfigurations {
          ...tableConfigurations
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${localizedStringFragments.localizedString}
  ${tableConfigurationsFragments.tableConfigurations}
`
