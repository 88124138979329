// ES5 syntax used because of gatsby-node.js requires it
var localizeObject = function(object, language) {
  if (!object) return ''
  switch (language) {
    case 'fi':
      return object.fi_FI
    case 'sv':
      return object.sv_FI
    case 'en':
      return object.en_GB
    case 'et':
      return object.et_EE
    default:
      break
  }
}

module.exports.localizeObject = localizeObject
