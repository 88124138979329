import React, { Component } from 'react'
import textInputStyles from './textInput.module.css'

/*  Props
      -id
      -type
      -active
      -label
      -value
      -onChange
      -disabled
      -onFocus
      -onBlur
*/
class TextInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focus: false,
      maxError: false,
    }
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    if (!props.id) throw new Error('expected id but none present')
  }

  onFocus(event) {
    this.setState({ focus: true })
    if (this.props.onFocus) this.props.onFocus(event)
  }

  onBlur(event) {
    this.setState({ focus: false })
    if (this.props.onBlur) this.props.onBlur(event)
  }

  render() {
    const { id, type, active, label, value, onBlur, onFocus, ...otherProps } = this.props
    return (
      <div>
        <div className={textInputStyles.floatingLabelInput}>
          <div className={textInputStyles.floatingLabelInputContainer}>
            <input
              className={[textInputStyles.floatingInput, active ? textInputStyles.opacity : ''].join(' ')}
              id={id}
              type={type}
              active={active}
              autoComplete="off"
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              value={value}
              {...otherProps}
            />
            <label className={[textInputStyles.floatingLabel, active || this.state.focus ? textInputStyles.transform : ''].join(' ')} htmlFor={id}>
              {label}
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default TextInput
