import React from 'react'
import checkBoxStyles from './checkBox.module.css'

const checkBox = props => {
  return (
    <div className={checkBoxStyles.wrapper}>
      <label className={props.disabled ? checkBoxStyles.containerDisabled : checkBoxStyles.container}>
        {props.label}
        <input name={props.name} type="checkbox" checked={props.checked} value={props.value} onChange={props.onChange} disabled={props.disabled} />
        <span className={props.disabled ? checkBoxStyles.checkmarkDisabled : checkBoxStyles.checkmark} />
      </label>
    </div>
  )
}

export default checkBox
